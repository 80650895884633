import React from "react"
import { motion } from "framer-motion"
import Img from "gatsby-image"

const EventCard = ({ variants, transition, className, ...props }) => {
  const categoryColor = props.category ? props.category.displayColor : ""

  return (
    <motion.div
      variants={variants}
      transition={transition}
      className={`event ${className} ${categoryColor.toLowerCase()}`}
      {...props}
    >
      <a target="_blank" className="flex w-full" href={props.target}>
        <div className="event__content min-h-event flex-auto">
          <div className="event__image w-full md:w-1/2 ">
            {props.day ? (
              <div className="event__date">
                <span className="event__date__day">{props.day}</span>
                <span className="event__date__month">{props.month}</span>
              </div>
            ) : (
              ""
            )}

            <Img
              fluid={props.image}
              alt={props.imageTitle}
              className="min-h-event flex-auto"
            />
          </div>
          <div className="event__details flex-auto ">
            <div className="event__body w-full h-full flex flex-auto flex-col justify-between">
              <div className="event__title ">
                <div className="kicker">
                  {props.category
                    ? `${props.category.category}`
                    : "Outros Eventos"}
                </div>
                <h3>{`${props.title}`}</h3>

                {props.presentedBy ? (
                  <div className="flex align-middle text-sm align-middle leading-normal my-2">
                    {props.presentedBy}
                  </div>
                ) : (
                  ""
                )}

                {props.when ? (
                  <div className="flex align-middle text-sm align-middle leading-normal mb-8 mt-2">
                    {props.when}
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="event__cta">
                <span>
                  {props.eventType ? `${props.eventType}` : "Free Webinar"}
                </span>
                <span>
                  {props.callToAction ? `${props.callToAction}` : "Watch now"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </motion.div>
  )
}

// EventCard.EropTypes = {
//   siteTitle: PropTypes.string,
// }

// EventCard.EefaultProps = {
//   siteTitle: ``,
// }

export default EventCard
